body {
  margin: 0;
  font-family: 'Mandali', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.notification-type-info > .ant-notification-notice-content > .ant-notification-notice-with-icon > .ant-notification-notice-message, .notification-type-info > .ant-notification-notice-content > .ant-notification-notice-close{
  color: rgb(255,255,255) !important;
}
.notification-type-info > .ant-notification-notice-content > .ant-notification-notice-with-icon >.anticon.ant-notification-notice-icon-success{
    color: rgb(255,255,255) !important;
}
.notification-type-info > .ant-notification-notice-close{
    color: rgb(255,255,255) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 800px) {
  .ant-skeleton-image{
    width: 120px !important;
  }
}

.responsive-two-column {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.responsive-two-column-item {
  width: 50%;
}

@media only screen and (max-width: 800px) {
    .responsive-two-column-item {
      width: 100%;
      margin-bottom: 50px;
    }
}
@media only screen and (max-width: 400px) {
    .ant-select-dropdown{
        width: 100% !important;
    }
}
.ant-btn-text{
  color: #ff0000d9 !important;
}
.ant-drawer-body{
 background:#fefefe !important; 
}
/* 
* {
  transition: all 500ms ease-in-out;
} */